<template>
  <FormComponent
    :dialog="dialog"
    :config="config"
    :valid="valid"
    @save:form="saveConfig"
    @update:dialog="dialog = $event"
    @update:valid="valid = $event"
    @load:init-data="handleInitData"
    @clear:form="clear($event)"
    :advanced-options="{format: true, itemsLimit: true}"
  >

    <template v-slot:custom-fields>
      <v-row>
        <v-col cols="12">
          <v-select
            :items="directions"
            :no-data-text="$t('general.fields.noDataAvailable')"
            :clearable="true"
            :label="$t('widgets.dialogs.filters.order')"
            v-model="config.filter.direction"
            item-color="primary"
            item-text="name"
            item-value="key"
            dense
            flat
            outlined
            color="primary"
            :disabled="loading.process"
            :rules="selectRule"
          >
            <template v-slot:label>
              {{ $t('widgets.dialogs.filters.order') }}
              <strong class="red--text">*</strong>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </template>
  </FormComponent>

</template>

<script>
import ColorPicker from "@dashboard/components/common/ColorPicker.vue";
import IconPicker from "@dashboard/components/common/IconPicker.vue";
import FormComponent from "@dashboard/components/common/FormComponent.vue";
import {CommonWidgetMixin} from "@/module/dashboard/mixins/common-widget.mixin";
import {AgtraceWidgetMixin} from "@/module/dashboard/mixins/agtrace-widget.mixin";
import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin";

import i18n from "@/plugins/i18n";

export default {
  name: 'QrTopCitiesDialog',
  components: {FormComponent, IconPicker, ColorPicker},
  mixins: [AgtraceWidgetMixin, CommonWidgetMixin, FormWidgetMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: i18n.t('widgets.names.TOP_CITY_QR_VISIT'),
        icon: 'mdi-chart-box-outline',
        color: '#232323',
        itemsLimit: 0,
        filter: {
          direction: null
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'TOP_CITY_QR_VISIT',
        type: 'CHART'
      })
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      config: {
        id: null,
        title: i18n.t('widgets.names.TOP_CITY_QR_VISIT'),
        icon: 'mdi-chart-box-outline',
        color: '#232323',
        itemsLimit: 0,
        filter: {
          direction: null
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'TOP_CITY_QR_VISIT',
        type: 'CHART'
      },
      directions: [
        {
          key: 'ASC',
          name: 'ASC'
        },
        {
          key: 'DESC',
          name: 'DESC'
        }
      ],
      defaultConfig: null
    };
  },
  watch: {
    valid() {
      this.$emit('update:validation', this.valid);
    }
  },
  created() {
    this.defaultConfig = { ...this.config }
    this.config = {
      ...this.item.props,
      format: this.item.props.format || {
        region: null,
        afterPoint: null
      },
      subtype: "TOP_CITY_QR_VISIT",
      icon: "mdi-chart-box-outline",
      info: "TOP_CITY_QR_VISIT",
    };
  },
  computed: {
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    saveConfig(data) {
      const config = {
        ...data,
        filter: {
          direction: this.config.filter.direction.key || this.config.filter.direction
        },
        initialized: true
      }

      this.$emit('update:validation', this.valid);
      this.$emit('update:config', config);
      this.dialog = false;
    },
    async handleInitData() {
      this.config = { ...this.item.props };
      if (this.item.initialized) {
        this.config.filter.direction = _.find(this.directions, { key: this.config.filter.direction.key || this.config.filter.direction })
      } else {
        this.config = { ...this.defaultConfig };
      }
    },
    clear(e) {
      this.config = {...e}
      this.saveConfig(e)
    }
  }
};
</script>

<style scoped>
.v-card {
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.headline {
  color: #424242;
}

:deep(.v-input.v-color-picker__alpha) {
  display: none !important;
}

:deep(.v-color-picker__preview:not(.v-color-picker__preview--hide-alpha) .v-color-picker__hue) {
  margin-bottom: 0 !important;
}
</style>
