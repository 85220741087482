<template>
  <FormComponent
    :dialog="dialog"
    :config="config"
    :valid="valid"
    @save:form="saveConfig"
    @update:dialog="dialog = $event"
    @update:valid="valid = $event"
    @load:init-data="handleInitData"
    @clear:form="clear($event)"
    :advanced-options="{format: true, itemsLimit: true}"
  >

    <template v-slot:custom-fields>
      <LoadingWrapper :loading="loadingFields">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              multiple
              :items="widgetProcess"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              v-model="config.filter.processIds"
              item-color="primary"
              item-text="proccess_name"
              item-value="id"
              dense
              flat
              outlined
              color="primary"
              :loading="loading.process"
              :rules="selectRule"
              @change="onSelectedProcess()"
            >
              <template v-slot:prepend-item>
                <v-list-item @click="() => toggleSelection('widgetProcess')">
                  <v-list-item-action>
                    <v-icon color="primary">{{ computeSelectionIcon('widgetProcess') }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    {{ config.filter.processIds.length === widgetProcess.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:label>
                {{ $t('widgets.dialogs.filters.process') }}
                <strong class="red--text">*</strong>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small>
                  <span>{{ item.proccess_name | truncate(18) }}</span>
                </v-chip>
                <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
                >(+{{ config.filter.processIds.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 200px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="primary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item.proccess_name"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="positions"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              :label="$t('widgets.dialogs.filters.locationPosition')"
              v-model="config.filter.locationPosition"
              item-color="primary"
              item-text="name"
              item-value="key"
              dense
              flat
              outlined
              color="primary"
              :disabled="loading.process"
              :rules="selectRule"
            >
              <template v-slot:label>
                {{ $t('widgets.dialogs.filters.locationPosition') }}
                <strong class="red--text">*</strong>
              </template>
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="types"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              :label="$t('widgets.dialogs.filters.locationType')"
              v-model="config.filter.locationType"
              item-color="primary"
              item-text="name"
              item-value="key"
              dense
              flat
              outlined
              color="primary"
              :disabled="loading.process"
              :rules="selectRule"
            >
              <template v-slot:label>
                {{ $t('widgets.dialogs.filters.locationType') }}
                <strong class="red--text">*</strong>
              </template>
            </v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="directions"
              :no-data-text="$t('general.fields.noDataAvailable')"
              :clearable="true"
              :label="$t('widgets.dialogs.filters.order')"
              v-model="config.filter.direction"
              item-color="primary"
              item-text="name"
              item-value="key"
              dense
              flat
              outlined
              color="primary"
              :disabled="loading.process"
              :rules="selectRule"
            >
              <template v-slot:label>
                {{ $t('widgets.dialogs.filters.order') }}
                <strong class="red--text">*</strong>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </LoadingWrapper>
    </template>
  </FormComponent>

</template>

<script>
import ColorPicker from "@dashboard/components/common/ColorPicker.vue";
import IconPicker from "@dashboard/components/common/IconPicker.vue";
import FormComponent from "@dashboard/components/common/FormComponent.vue";
import i18n from "@/plugins/i18n";
import {AgtraceWidgetMixin} from "@/module/dashboard/mixins/agtrace-widget.mixin";
import {CommonWidgetMixin} from "@/module/dashboard/mixins/common-widget.mixin";
import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin";
import ReadMore from "@/components/ReadMoreComponent.vue";
import LoadingWrapper from "@/module/dashboard/components/common/LoadingWrapper.vue";


export default {
  name: 'LocationDialog',
  components: {LoadingWrapper, ReadMore, FormComponent, IconPicker, ColorPicker},
  mixins: [AgtraceWidgetMixin, CommonWidgetMixin,FormWidgetMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: i18n.t('widgets.names.COUNT_PROCESS_BY_LOCATION'),
        icon: 'mdi-chart-box-outline',
        color: '#232323',
        itemsLimit: 0,
        filter: {
          processIds: null,
          locationPosition: null,
          locationType: null,
          direction: null
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'COUNT_PROCESS_BY_LOCATION',
        type: 'CHART'
      })
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      config: {
        id: null,
        title: i18n.t('widgets.names.COUNT_PROCESS_BY_LOCATION'),
        icon: 'mdi-chart-box-outline',
        color: '#232323',
        itemsLimit: 0,
        filter: {
          processIds: [],
          locationPosition: null,
          locationType: null,
          direction: null
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'COUNT_PROCESS_BY_LOCATION',
        type: 'CHART'
      },
      positions: [
        {
          key: 'ORIGIN',
          name: i18n.t('records.fields.origin')
        },
        {
          key: 'DESTINATION',
          name: i18n.t('records.fields.destination')
        }
      ],
      types: [
        {
          key: 'PRIMARY',
          name: i18n.t('locations.fields.name')
        },
        {
          key: 'SECONDARY',
          name: i18n.t('storage.fields.name')
        }
      ],
      directions: [
        {
          key: 'ASC',
          name: 'ASC'
        },
        {
          key: 'DESC',
          name: 'DESC'
        }
      ],
      defaultConfig: null,
      loadingFields: false
    };
  },
  watch: {
    valid() {
      this.$emit('update:validation', this.valid);
    }
  },
  created() {
    this.defaultConfig = { ...this.config };
    this.config = {
      ...this.item.props,
      format: this.item.props.format || {
        region: null,
        afterPoint: null
      },
      subtype: "COUNT_PROCESS_BY_LOCATION",
      icon: "mdi-chart-box-outline",
      info: "COUNT_PROCESS_BY_LOCATION",
    };

    // !TODO: Quitar despues
    // Inicializa processIds como un array si aún no lo es
    // this.config.filter.processIds = Array.isArray(this.config.filter.processIds) ? this.config.filter.processIds : this.config.filter.processIds ? [this.config.filter.processIds] : [];
  },
  computed: {
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    saveConfig(data) {
      const config = {
        ...data,
        filter: {
          processIds: [...this.config.filter.processIds],
          locationPosition: this.config.filter.locationPosition.key || this.config.filter.locationPosition,
          locationType: this.config.filter.locationType.key || this.config.filter.locationType,
          direction: this.config.filter.direction.key || this.config.filter.direction
        },
        initialized: true
      };

      this.$emit('update:validation', this.valid);
      this.$emit('update:config', config);
      this.dialog = false;
    },
    async handleInitData() {
      this.config = { ...this.item.props };
      if (this.item.initialized) {
        this.loadingFields = true;

        // await this.onSelectedProcess();

        this.config.filter.locationPosition = _.find(this.positions, { key: this.config.filter.locationPosition.key || this.config.filter.locationPosition });
        this.config.filter.locationType = _.find(this.types, { key: this.config.filter.locationType.key || this.config.filter.locationType });
        this.config.filter.direction = _.find(this.directions, { key: this.config.filter.direction.key || this.config.filter.direction });

        this.loadingFields = false;
      } else {
        this.config = { ...this.defaultConfig };
      }
    },
    clear(e) {
      this.config = {...e}
      this.saveConfig(e)
    }
  }
};
</script>

<style scoped>
.v-card {
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.headline {
  color: #424242;
}

:deep(.v-input.v-color-picker__alpha) {
  display: none !important;
}

:deep(.v-color-picker__preview:not(.v-color-picker__preview--hide-alpha) .v-color-picker__hue) {
  margin-bottom: 0 !important;
}
</style>
