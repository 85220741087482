<template>
  <FormComponent
    :dialog="dialog"
    :config="config"
    :valid="valid"
    @save:form="saveConfig"
    @update:dialog="dialog = $event"
    @update:valid="valid = $event"
    @load:init-data="handleInitData"
    @clear:form="clear($event)"
  >
    <template v-slot:custom-fields>
      <v-container class="mx-0 px-0">
        <v-row>
          <v-col cols="12" class="py-0">
            <v-autocomplete
              outlined
              dense
              color="primary"
              item-color="primary"
              v-model="config.filter.entity"
              :items="entities"
              :rules="selectRule"
              clearable
              @change="handleEntityChange"
            >
              <template v-slot:label>
                {{ $t("widgets.dialogs.filters.entity") }}
                <strong class="red--text">*</strong>
              </template>
              <template v-slot:item="{ item }">
                {{ $t('modules.name.' + item).toUpperCase() }}
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small>
                  <span>{{ $t("modules.name." + item).toUpperCase() | truncate(18) }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <classification-type-filed
            :config="config"
            :showField="showField"
            :cols="6"
            filter-key="entity"
          />
          <v-col cols="6" class="py-0 pb-4" v-if="showField('active')">
            <v-select
              v-model="config.filter.active"
              :items="activeOptions"
              :label="$t('widgets.dialogs.filters.active')"
              item-value="value"
              item-text="name"
              item-color="primary"
              color="primary"
              outlined
              dense
              clearable
              hide-details
            />
          </v-col>
          <v-col cols="12" class="py-0 pb-4" v-if="showField('stages')">
            <v-select
              v-model="config.filter.stages"
              :items="storageDeviceTypesActiveByCompany"
              :label="$t('widgets.filters.stages')"
              item-text="storage_type_name"
              item-value="storage_device_type_id"
              item-color="primary"
              color="primary"
              outlined
              dense
              multiple
              clearable
              hide-details
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small>
                  <span>{{ item.storage_type_name | truncate(18) }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                >(+{{ config.filter.stages.length - 1 }}
                  {{ $t('general.titles.others') }})</span
                >
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </FormComponent>
</template>

<script>
  import FormComponent from "@/module/dashboard/components/common/FormComponent.vue";
  import ColorPicker from "@/module/dashboard/components/common/ColorPicker.vue";
  import IconPicker from "@/module/dashboard/components/common/IconPicker.vue";
  import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin";
  import { ClassificationTypeMixin } from "@/module/dashboard/mixins/classification-type.mixin";
  import i18n from "@/plugins/i18n";
  import { getByProperty } from '@/utils';
  import ClassificationTypeFiled from '../../../common/ClassificationTypeFiled.vue';
  import { mapGetters } from "vuex"

  export default {
    name: "CountDialog",
    components: { FormComponent, IconPicker, ColorPicker, ClassificationTypeFiled },
    mixins: [FormWidgetMixin, ClassificationTypeMixin],
    props: {
      item: {
        type: Object,
        required: false,
        default: () => ({
          id: null,
          title: i18n.t('widgets.names.COUNT'),
          icon: 'mdi-card-bulleted',
          color: '#232323',
          filter: {
            entity: null,
            classificationTypes: [],
            active: null,
          },
          format: {
            region: null,
            afterPoint: null
          },
          subtype: "COUNT",
          type: "CARD",
        }),
      },
    },
    data() {
      return {
        dialog: false,
        valid: false,
        config: {
          id: null,
          title: i18n.t('widgets.names.COUNT'),
          icon: 'mdi-card-bulleted',
          color: '#232323',
          filter: {
            entity: null,
            classificationTypes: [],
            active: null,
            stages: []
          },
          format: {
            region: null,
            afterPoint: null
          },
          subtype: "COUNT",
          type: "CARD",
        },
        entities: [
          "Record",
          "Document",
          "Location",
          "StorageDevice",
          "Culture",
          "Season",
          "Input",
          "Operator",
          "Equipment",
          "Survey",
          "Producer",
        ],
        entityConfig: {
          Document: ['active'],
          Location: ['classificationTypes', 'stages', 'active'],
          StorageDevice: ['classificationTypes', 'stages', 'active'],
          Culture: ['active'],
          Season: ['active'],
          Input: ['active'],
          Operator: ['active'],
          Equipment: ['active'],
          Producer: ['active']
        },
        activeOptions: [
          { id: 1, value: true, name: i18n.t('documents.filters.isActive.activated') },
          { id: 2, value: false, name: i18n.t('documents.filters.isActive.desactivated') }
        ],
        defaultConfig: null
      }
    },
    watch: {
      valid() {
        this.$emit("update:validation", this.valid)
      },
    },
    async created() {
      this.defaultConfig = { ...this.config }
      this.config = {
        ...this.item.props,
        format: this.item.props.format || {
          region: null,
          afterPoint: null
        },
        subtype: "COUNT",
        icon: "mdi-card-bulleted",
        info: "COUNT",
      }

      await this.loadDependenciesByCard(this.item.type, this.item.subtype, this.config.filter.entity)
    },
    computed: {
      ...mapGetters("storage_type", ["storageDeviceTypesActiveByCompany"]),
      isEditingEnabled() {
        return this.$store.getters["dashboard/IS_EDITING_MODE"]
      },
      showField() {
        return (field) => {
          return this.config.filter.entity &&
            this.entityConfig[this.config.filter.entity] &&
            this.entityConfig[this.config.filter.entity].includes(field);
        }
      },
    },
    methods: {
      saveConfig(data) {
        const config = {
          ...data,
          filter: {
            entity: this.config.filter.entity,
            classificationTypes: getByProperty(this.config.filter.classificationTypes, 'id'),
            active: getByProperty(this.config.filter.active, 'value'),
            stages: getByProperty(this.config.filter.stages, 'storage_device_type_id'),
          },
          initialized: true,
        }

        this.$emit("update:config", config)
        this.$emit("update:validation", this.valid)
        this.dialog = false
      },
      async handleInitData() {
        this.config = { ...this.item.props };
        if (this.item.initialized) {
          console.log(this.config)
          if (this.config.filter && this.config.filter.classificationTypes)
            this.config.filter.classificationTypes = this.config.filter.classificationTypes
              .map(id => this.findClassificationById(id))
              .filter(item => item !== null);

          if (this.config.filter && this.config.filter.stages)
            this.config.filter.stages = this.config.filter.stages
              .map(s => this.storageDeviceTypesActiveByCompany.find(item => item.storage_device_type_id === getByProperty(s, 'storage_device_type_id'))?.storage_device_type_id)
              .filter(item => item !== null);
              
          this.config.filter.active = _.find(this.activeOptions, { value: this.config.filter.active })? this.config.filter.active : null
        } else {
          this.config = { ...this.defaultConfig };
        }
      },
      clear(e) {
        this.config = {...e}
        this.saveConfig(e)
      },
      handleEntityChange() {
        this.config.filter.classificationTypes = [];
        this.config.filter.active = null;
        this.$set(this.config.filter, 'stages', []);
      }
    },
  }
</script>

<style scoped></style>
