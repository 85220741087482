<template>
  <FormComponent
    :dialog="dialog"
    :config="config"
    :valid="valid"
    @save:form="saveConfig"
    @update:dialog="dialog = $event"
    @update:valid="valid = $event"
    @load:init-data="handleInitData"
    @clear:form="clear($event)"
  >

    <template v-slot:custom-fields>
      <LoadingWrapper :loading="loadingFields">
        <v-container>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-autocomplete
                multiple
                :items="widgetProcess"
                :no-data-text="$t('general.fields.noDataAvailable')"
                :clearable="true"
                v-model="config.filter.processIds"
                item-color="primary"
                item-text="proccess_name"
                item-value="id"
                dense
                flat
                outlined
                color="primary"
                :loading="loading.process"
                :rules="selectRule"
                @change="onSelectedProcess()"
                :menu-props="{ closeOnContentClick: false }"
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="() => toggleSelection('widgetProcess')">
                    <v-list-item-action>
                      <v-icon color="primary">{{ computeSelectionIcon('widgetProcess') }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      {{ config.filter.processIds.length === widgetProcess.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:label>
                  {{ $t('widgets.dialogs.filters.process') }}
                  <strong class="red--text">*</strong>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" small>
                    <span>{{ item.proccess_name | truncate(18) }}</span>
                  </v-chip>
                  <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
                  >(+{{ config.filter.processIds.length - 1 }}
                        {{ $t('general.titles.others') }})</span
                  >
                </template>
                <template v-slot:item="{ item, attrs }">
                  <div style="max-width: 200px">
                    <v-checkbox
                      :input-value="attrs.inputValue"
                      color="primary"
                      class="pt-2 pb-2 mt-0"
                      :hide-details="true"
                    >
                      <template v-slot:label>
                        <read-more
                          :classes="'mb-0'"
                          :text="item.proccess_name"
                          :max-chars="80"
                          :font-size="14"
                        />
                      </template>
                    </v-checkbox>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-autocomplete
                multiple
                :items="groups"
                :no-data-text="$t('general.fields.noDataAvailable')"
                :clearable="true"
                :label="$t('widgets.dialogs.filters.group')"
                v-model="groupSelected"
                item-color="primary"
                item-text="name"
                return-object
                dense
                flat
                outlined
                color="primary"
                :disabled="loading.process && groups.length === 0"
                :rules="selectRule"
                @change="getStructuresAndValues(types)"
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="() => toggleSelection('groups', types)">
                    <v-list-item-action>
                      <v-icon color="primary">{{ computeSelectionIcon('groups') }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      {{ groupSelected.length === groups.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:label>
                  {{ $t("widgets.dialogs.filters.group") }}
                  <strong class="red--text">*</strong>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" small>
                    <span>{{ item.name | truncate(5) }}</span>
                  </v-chip>
                  <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
                  >(+{{ groupSelected.length - 1 }}
                          {{ $t('general.titles.others') }})</span
                  >
                </template>
                <template v-slot:item="{ item, attrs }">
                  <div style="max-width: 200px">
                    <v-checkbox
                      :input-value="attrs.inputValue"
                      color="primary"
                      class="pt-2 pb-2 mt-0"
                      :hide-details="true"
                    >
                      <template v-slot:label>
                        <read-more
                          :classes="'mb-0'"
                          :text="item.name"
                          :max-chars="80"
                          :font-size="14"
                        />
                      </template>
                    </v-checkbox>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-autocomplete
                multiple
                :items="displayedNestedValues"
                :no-data-text="$t('general.fields.noDataAvailable')"
                @update:search-input="handleNestedValuesSearchChange($event)"
                :clearable="true"
                v-model="dataToCheck"
                item-color="primary"
                item-text="value"
                return-object
                small-chips
                dense
                flat
                outlined
                color="primary"
                :rules="arraySelectionRules({ min: 1, max: 10, required: true })"
                :disabled="nestedValues.length === 0"
              >
                <template v-slot:prepend-item>
                  <v-alert
                    v-if="dataToCheck.length > 10"
                    type="error"
                    :value="true"
                    top
                    text
                    tile
                    shaped
                  >
                    <p class="ma-0 pa-0" style="width: 180px;">{{ $t("general.rules.maxSelection", { max: 10 }) }}</p>
                  </v-alert>
                  <v-list-item @click="() => toggleSelection('nestedValues')">
                    <v-list-item-action>
                      <v-icon color="primary">{{ computeSelectionIcon('nestedValues') }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      {{ dataToCheck.length === nestedValues.length ? $t('general.titles.deselectAll') : $t('general.titles.selectAll') }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item, attrs }">
                  <div style="max-width: 180px">
                    <v-checkbox
                      :input-value="attrs.inputValue"
                      color="primary"
                      class="pt-2 pb-2 mt-0"
                      :hide-details="true"
                    >
                      <template v-slot:label>
                        <read-more
                          :classes="'mb-0'"
                          :text="item.value"
                          :max-chars="40"
                          :font-size="14"
                        />
                      </template>
                    </v-checkbox>
                  </div>
                </template>
                <template v-slot:append-item>
                  <AFooterPaginatorField
                    :items-length="filteredNestedValues.length"
                    :items-per-page="pagination.nestedValues.itemsPerPage"
                    :itemPerPageText="false"
                    :current-page="pagination.nestedValues.currentPage"
                    :valid="true"
                    @update:current-page="(e) => updatePagination('nestedValues', { page: e.page, itemsPerPage: e.itemsPerPage })"
                    @update:items-per-page="(e) => updatePagination('nestedValues', { page: e.page, itemsPerPage: e.itemsPerPage })"
                  />
                </template>
                <template v-slot:label>
                  {{ $t('widgets.dialogs.filters.structures') }}
                  <strong class="red--text">*</strong>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" small>
                    <span>{{ item.value | truncate(5) }}</span>
                  </v-chip>
                  <span style='font-size: 10px !important;' v-if="index === 1" class="grey--text caption"
                  >(+{{ dataToCheck.length - 1 }}
                          {{ $t('general.titles.others') }})</span
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="py-0 pb-4">
              <v-autocomplete
                :no-data-text="$t('general.fields.noDataAvailable')"
                @update:search-input="handleUnitsSearchChange($event)"
                item-text="value"
                return-object
                outlined
                dense
                color="primary"
                item-color="primary"
                v-model="config.filter.unitShowed"
                :items="displayedUnits"
                :disabled="units.length === 0"
                :rules="selectRule"
                clearable
              >
                <template v-slot:label>
                  {{ $t('widgets.dialogs.filters.unitShowed') }}
                  <strong class="red--text">*</strong>
                </template>
                <template v-slot:item="{ item, attrs }">
                  <div style="max-width: 180px">
                    <read-more
                      :classes="'mb-0'"
                      :text="item.value"
                      :max-chars="40"
                      :font-size="14"
                    />
                  </div>
                </template>
                <template v-slot:append-item>
                  <AFooterPaginatorField
                    :items-length="filteredUnits.length"
                    :items-per-page="pagination.units.itemsPerPage"
                    :itemPerPageText="false"
                    :current-page="pagination.units.currentPage"
                    :valid="true"
                    @update:current-page="(e) => updatePagination('units', { page: e.page, itemsPerPage: e.itemsPerPage })"
                    @update:items-per-page="(e) => updatePagination('units', { page: e.page, itemsPerPage: e.itemsPerPage })"
                  />
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </LoadingWrapper>
    </template>
  </FormComponent>

</template>

<script>
import ColorPicker from "@/module/dashboard/components/common/ColorPicker.vue";
import IconPicker from "@/module/dashboard/components/common/IconPicker.vue";
import FormComponent from "@/module/dashboard/components/common/FormComponent.vue";
import { AgtraceWidgetMixin } from "@/module/dashboard/mixins/agtrace-widget.mixin";
import { CommonWidgetMixin } from "@/module/dashboard/mixins/common-widget.mixin";
import { FormWidgetMixin } from "@/module/dashboard/mixins/form-widget.mixin";
import i18n from "@/plugins/i18n";
import ReadMore from "@/components/ReadMoreComponent.vue";
import LoadingWrapper from "@/module/dashboard/components/common/LoadingWrapper.vue";
import FooterPaginationComponent from "@/module/records/components/commons/FooterPaginationComponent.vue"
import AFooterPaginatorField from "@/components/common/AFooterPaginatorField.vue";

export default {
  name: 'ProductDialog',
  components: {AFooterPaginatorField, FooterPaginationComponent, LoadingWrapper, ReadMore, FormComponent, IconPicker, ColorPicker},
  mixins: [AgtraceWidgetMixin, CommonWidgetMixin, FormWidgetMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => ({
        id: null,
        title: i18n.t('widgets.names.AGTRACE_COUNT_SELECTED_PRODUCTS'),
        icon: 'mdi-chart-arc',
        color: '#232323',
        filter: {
          processIds: [],
          groupIds: [],
          nestedValues: [],
          unitShowed: null
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'AGTRACE_COUNT_SELECTED_PRODUCTS',
        type: 'CHART'
      })
    }
  },
  data() {
    return {
      dialog: false,
      valid: true,
      config: {
        id: null,
        title: i18n.t('widgets.names.AGTRACE_COUNT_SELECTED_PRODUCTS'),
        icon: 'mdi-chart-arc',
        color: '#232323',
        filter: {
          processIds: [],
          groupIds: [],
          nestedValues: [],
          unitShowed: null
        },
        format: {
          region: null,
          afterPoint: null
        },
        subtype: 'AGTRACE_COUNT_SELECTED_PRODUCTS',
        type: 'CHART'
      },
      defaultConfig: null,
      loadingFields: false,
      selectMaxValues: [
        v => v.length <= 10 || 'No puedes seleccionar más de 10 elementos',
      ],
    };
  },
  watch: {
    valid() {
      this.$emit('update:validation', this.valid);
    }
  },
  created() {
    this.defaultConfig = { ...this.config };
    this.config = {
      ...this.item.props,
      format: this.item.props.format || {
        region: null,
        afterPoint: null
      },
      subtype: "AGTRACE_COUNT_SELECTED_PRODUCTS",
      icon: "mdi-chart-arc",
      info: "AGTRACE_COUNT_SELECTED_PRODUCTS",
    };

    // !TODO: Quitar despues
    // Inicializa las propiedades del filtro como arrays si aún no lo son
    // this.config.filter.processIds = Array.isArray(this.config.filter.processIds) ? this.config.filter.processIds : this.config.filter.processIds ? [this.config.filter.processIds] : [];
    // this.config.filter.groupIds = Array.isArray(this.config.filter.groupIds) ? this.config.filter.groupIds : this.config.filter.groupIds ? [this.config.filter.groupIds] : [];
    // this.config.filter.nestedValues = Array.isArray(this.config.filter.nestedValues) ? this.config.filter.nestedValues : [];
    // unitShowed puede permanecer como un valor único si es el caso deseado
  },

  computed: {
    isEditingEnabled() {
      return this.$store.getters['dashboard/IS_EDITING_MODE'];
    }
  },
  methods: {
    saveConfig(data) {
      const config = {
        ...data,
        filter: {
          processIds: [...this.config.filter.processIds],
          groupIds: this.groupSelected.map(g => g.id),
          nestedValues: this.dataToCheck.map(d => d.value),
          unitShowed: this.config.filter.unitShowed && this.config.filter.unitShowed.id ? this.config.filter.unitShowed.id : this.config.filter.unitShowed || null,
        },
        initialized: true
      };

      this.$emit('update:validation', this.valid);
      this.$emit('update:config', config);
      this.dialog = false;
    },
    async handleInitData() {
      this.config = { ...this.item.props };
      if (this.item.initialized) {
        this.loadingFields = true;

        // Primero, asegúrate de que los procesos se carguen
        // await this.onSelectedProcess();

        await this.getGroups();
        this.groupSelected = this.groups.filter(g => this.config.filter.groupIds.includes(g.id));

        // Asegúrate de que los grupos estén seleccionados antes de proceder
        await this.$nextTick();

        await this.getStructuresAndValues(this.types);

        // Asegúrate de que las estructuras estén seleccionadas antes de proceder
        await this.$nextTick();

        this.dataToCheck = this.nestedValues.filter(nv => this.config.filter.nestedValues.includes(nv.value));
        this.config.filter.unitShowed = _.find(this.units, {id: this.config.filter.unitShowed ? this.config.filter.unitShowed.id || this.config.filter.unitShowed : null})

        this.loadingFields = false;
      } else {
        this.config = { ...this.defaultConfig };
      }
    },

    clear(e) {
      this.config = {...e}
      this.saveConfig(e)
    }
  }
}
</script>

<style scoped>
.v-card {
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #424242;
}
</style>
