<template>
  <v-col :cols="cols" class="py-0 pb-4" v-if="showField('classificationTypes')">
    <v-autocomplete v-model="config.filter.classificationTypes" :items="currentClassifications" multiple
      :label="$t('storage.fields.classificationType')" item-value="id" item-text="name" item-color="primary"
      color="primary" outlined dense clearable>
      <template v-slot:selection="{ item, index }">
        <v-chip v-if="index === 0" small>
          <span>{{ item.name | truncate(18) }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text caption"
        >(+{{ config.filter.classificationTypes.length - 1 }}
                  {{ $t('general.titles.others') }})</span
        >
      </template>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { ClassificationTypeMixin } from '@/module/dashboard/mixins/classification-type.mixin'

export default {
  name: 'ClassificationTypeField',
  props: {
    config: {
      type: Object,
      required: true
    },
    cols: {
      type: Number,
      required: true,
      default: true
    },
    showField: {
      type: Function,
      required: true
    },
    filterKey: {
      type: String,
      required: true
    }
  },
  mixins: [ClassificationTypeMixin]
};
</script>

<style scoped></style>