<template>
  <v-dialog v-model="dialog" max-width="600px" persistent>
    <template v-slot:activator="{ on:dialog }">
      <v-tooltip right>
        <template v-slot:activator="{ on:tooltip }">
          <v-btn :disabled="!layout.id"
                 icon small
                 v-on="{...tooltip}"
                 :loading="loading"
                 @click="firstInit(false)"
          >
            <v-icon small>mdi-filter-variant</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('dashboard.filters.settings') }}</span>
      </v-tooltip>
    </template>
    <v-card v-if="dialog">
      <v-card-title class="grey--text text--darken-3">
        {{ $t('dashboard.filters.settings') }}
        <v-chip v-if="isLocalFilters" class="custom__chip" color="primary" small
                text-color="white">
          {{ $t('dashboard.filters.local') }}
        </v-chip>
        <v-chip v-else class="custom__chip" color="primary" small text-color="white">
          {{ $t('dashboard.filters.global') }}
        </v-chip>
        <v-spacer/>
        <v-checkbox
          v-model="applyGlobally"
          color="primary"
          :label="$t('dashboard.filters.applyGlobally')"
        ></v-checkbox>
      </v-card-title>
      <v-alert
        v-if="!isValidSelection"
        border="left"
        type="error"
        dense
        tile
        text
      >
        {{ $t('general.notification.selectedItemsExceeded', {max: 1000}) }}
      </v-alert>
      <v-card-text class="pa-6">
        <DateRangePicker
          v-model="filters.dates"
          :label="$t('records.fields.date')"
          @update:dates="$set(filters, 'dates', [...$event])"
        />
        <DateRangePicker
          v-model="filters.recordDates"
          :label="$t('records.fields.registerDate')"
          @update:dates="$set(filters, 'recordDates', [...$event])"
        />
        <v-row>
          <v-col cols="12">
            <StagesPicker/>
          </v-col>
        </v-row>
        <ProcessPicker />
        <LocationOriginFilters
          :location-label="$t('locations.fields.name')"
          location-ref="locationsFilters"
          :locationsSelected="filters.primaryLocations"
          @update:locations:origin="filters.primaryLocations = getByProperty($event, 'locationId')"
          :storage-label="$t('storage.fields.name')"
          storage-ref="locationsFiltersOrigin"
          :storagesSelected="filters.secondaryLocations"
          :show-origin="true"
          :show-destination="true"
          @update:storages:origin="filters.secondaryLocations = getByProperty($event, 'storageDeviceId')"
          @on:valid-form="isValidSelection = $event"
          :autocomplete-props="{
            color: 'secondary',
            itemColor: 'secondary',
            smallChips: true,
            clearable: true,
            multiple: true,
            dense: true,
            outlined: true
          }"
          key="dashboardLocFilters"
        />
        <div class="d-flex flex-row justify-space-between align-center align-content-center">
          <v-col class="ma-0 pa-0" cols="6">
            <LocationTypePicker class="mr-3" />
          </v-col>
          <v-col class="ma-0 pa-0" cols="6">
            <ClassificationTypePicker class="ml-3" />
          </v-col>
        </div>
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-spacer></v-spacer>
        <v-btn small text color="neuter" @click="close()">{{ $t('general.buttons.cancel') }}</v-btn>
        <v-btn small elevation="0" color="primary" class="t-bw-primary--text" @click="save()" :disabled="!isValidSelection">
          {{ $t('general.buttons.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DateRangePicker from "@/module/dashboard/components/common/filters/DateRangePicker.vue";
import {mapActions, mapGetters} from "vuex";
import LocationDestinationFilters from "@/components/filters/LocationDestinationFilters.vue";
import LocationOriginFilters from "@/components/filters/LocationOriginFilters.vue";
import ReadMore from "@/components/ReadMoreComponent.vue";
import ProcessPicker from "@/module/dashboard/components/common/filters/ProcessPicker.vue";
import { uuidv4 } from "@/services/Web3IntegrationService"
import StagesPicker from "@/module/dashboard/components/common/filters/StagesPicker.vue"
import LocationTypePicker from "@/module/dashboard/components/common/filters/LocationTypePicker.vue"
import ClassificationTypePicker from "@/module/dashboard/components/common/filters/ClassificationTypePicker.vue"
import { getByProperty } from "@/utils"

export default {
  name: "GlobalFilters",
  components: {
    ClassificationTypePicker,
    LocationTypePicker,
    StagesPicker,
    ProcessPicker, ReadMore, LocationOriginFilters, LocationDestinationFilters, DateRangePicker},
  data() {
    return {
      dialog: false,
      isLocalFilters: false,
      loading: false,
      clone: null,
      isValidSelection: true
    }
  },
  watch: {
    'layout.id': {
      handler() {
        this.firstInit(true)
      },
      deep: true
    }
  },
  mounted() {
    const profile = JSON.parse(localStorage.getItem('profile'))
    let localFilters = JSON.parse(
        localStorage.getItem(`filters-${profile.company_id}-${this.layout.id}`) ||
        localStorage.getItem(`filters-${profile.company_id}-global`)
      )

    this.clone = {...localFilters}
    this.$root.$on('on:load-filters', () => {
      this.firstInit(true)
    })
  },
  computed: {
    ...mapGetters({
      layout: 'dashboard/LAYOUT'
    }),
    locations: {
      get() {
        return this.$store.getters['ui/GET_LOCATIONS_FILTERS']
      },
      set(val) {
        this.$store.dispatch('ui/SET_LOCATIONS_FILTERS', val)
      },
    },
    applyGlobally: {
      get() {
        return this.filters.applyGlobally;
      },
      set(value) {
        this.updateFilters({applyGlobally: value});
      }
    },
    filters: {
      get() {
        return this.$store.getters['dashboard/GET_FILTERS'];
      },
      set(val) {
        this.$store.commit('dashboard/SET_FILTERS', val);
      }
    },
  },
  methods: {
    getByProperty,
    uuidv4,
    ...mapActions("dashboard", ["updateFilters", "loadFilters", "saveFilters", "clearSpecificConfiguration"]),
    firstInit(fromSelectLayout) {
      this.loading = true;
      const profile = JSON.parse(localStorage.getItem('profile'))

      let filters = JSON.parse(
        localStorage.getItem(`filters-${profile.company_id}-${this.layout.id}`) ||
        localStorage.getItem(`filters-${profile.company_id}-global`)
      )

      if (filters) {
        this.filters = Object.assign({}, filters);
      }
      else {
        this.$store.commit('dashboard/CLEAR_FILTERS');
      }

      this.$root.$emit("set:location:origin", {
        locations: filters.primaryLocations,
        storageDevice: filters.secondaryLocations
      })

      this.isLocalFilters = !this.filters.isGlobal;
      this.loading = false;

      if (!fromSelectLayout)
        this.dialog = true;
    },
    clearLocaleFilters() {
      this.clearSpecificConfiguration(this.layout.id)
      this.isLocalFilters = false;
      this.filters.isGlobal = false;

      const profile = JSON.parse(localStorage.getItem('profile'))

      this.filters = JSON.parse(localStorage.getItem(`filters-${profile.company_id}-global`))
    },
    save() {
      this.saveFilters({
        layoutId: this.layout.id, isGlobal: this.applyGlobally
      })
      this.isLocalFilters = !this.applyGlobally;
      this.dialog = false;

      this.$root.$emit('on:recalculate')
      this.$root.$emit('apply:dashboard-filters', true);
    },
    close() {
      this.$set(this, 'filters', {...this.clone})
      this.$root.$emit('on:recalculate')
      this.loadFilters(this.layout.id)
      this.isLocalFilters = this.filters.isGlobal;
      this.dialog = false
    }
  },
  destroyed() {
    this.$root.$off('on:load-filters');
  }
}
</script>